import { ChangeEvent, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
} from "@mui/material";

import { ADMIN_BID_INCOTERMS_OPTION_LIST } from "@sellernote/shared/src/constants/forwarding/adminBid";
import { FreightType, Port } from "@sellernote/shared/src/types/common/common";

import AutoCompleteWithReactHookForm from "../../../../../AutoCompleteWithReactHookForm";

function ShipmentDataForm({ portList }: { portList: Port[] | undefined }) {
  const { control, watch } = useFormContext();

  const portOptionList = useMemo(() => {
    if (!portList) {
      return [];
    }

    return portList.map((portItem) => {
      return { label: portItem.name || portItem.nameEN, value: portItem.id };
    });
  }, [portList]);

  const getShipmentFreightType = (
    e: ChangeEvent<HTMLInputElement>,
    freightType: FreightType
  ) => {
    const shipmentFreightType: FreightType[] = watch(
      "shipmentData.freightTypes"
    );

    const newShipmentFreightType = e.target.checked
      ? [...shipmentFreightType, freightType]
      : shipmentFreightType.filter((v) => v !== freightType);

    return newShipmentFreightType;
  };

  return (
    <>
      <Grid item xs={12}>
        {/* 해당체크박스는 CheckBoxWithRHF처럼 개별이 아닌 묶음 체크박스 형식이라서 Controller로 직접 RHF를 사용 */}
        <Controller
          name="shipmentData.freightTypes"
          control={control}
          defaultValue={[]}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              required
              error={error !== undefined}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">운송유형</FormLabel>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      value="FCL"
                      onChange={(e) => {
                        field.onChange(getShipmentFreightType(e, "FCL"));
                      }}
                    />
                  }
                  label="FCL"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      value="LCL"
                      onChange={(e) => {
                        field.onChange(getShipmentFreightType(e, "LCL"));
                      }}
                    />
                  }
                  label="LCL"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      value="AIR"
                      onChange={(e) => {
                        field.onChange(getShipmentFreightType(e, "AIR"));
                      }}
                    />
                  }
                  label="AIR"
                />
              </FormGroup>
              {error && error.type === "required" && (
                <FormHelperText>필수 입력 사항입니다.</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={3}>
        <AutoCompleteWithReactHookForm
          name={"shipmentData.incoterms"}
          label="인코텀즈"
          control={control}
          required={true}
          options={ADMIN_BID_INCOTERMS_OPTION_LIST.map((optionItem) => {
            return optionItem.value;
          })}
        />
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={3}>
          <AutoCompleteWithReactHookForm
            name={"shipmentData.polId"}
            control={control}
            label="선적항(POL)"
            required={true}
            options={portOptionList}
          />
        </Grid>

        <Grid item xs={3}>
          <AutoCompleteWithReactHookForm
            name={"shipmentData.podId"}
            control={control}
            label="도착항(POD)"
            required={true}
            options={portOptionList}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ShipmentDataForm;

import React, { useCallback, useState } from "react";
import { ZoomInOutlined, ZoomOutOutlined } from "@mui/icons-material";
import { Button, Grid, Pagination, Tooltip, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import {
  FileCategoryTranslatedToKr,
  ForwardingAdminFileAuthority,
} from "@sellernote/shared/src/types/common/file";
import { BidUploadDataType } from "@sellernote/shared/src/types/common/upload";
import { BidProjectStatus } from "@sellernote/shared/src/types/forwarding/bid";
import { checkIsOfficeFile } from "@sellernote/shared/src/utils/common/etc";
import { omitWithEllipsis } from "@sellernote/shared/src/utils/common/string";
import { checkIfHaveAuthorityToDelete } from "@sellernote/shared/src/utils/forwarding/admin/trello";

import DeleteFileModal from "./DeleteFileModal";

function FileViewerController({
  fileName,
  s3Url,
  fileExtension,
  isPdf,
  isHwp,
  setPageNumber,
  setImageWidth,
  imageWidth,
  pdfScale,
  setPdfScale,
  pageNumber,
  numPages,
  fileCategory,
  id,
  projectStatus,
  isWithdrawn,
  dataType,
  attachmentId,
  handleFilePreviewClose,
  isNewWindow,
  fileAuthority,
  isAdmin,
  isPurchaseRequest,
  hidesDeleteButton,
}: {
  fileName: string;
  s3Url: string;
  fileExtension: string;
  isPdf: boolean;
  isHwp: boolean;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setImageWidth: React.Dispatch<React.SetStateAction<number>>;
  imageWidth: number;
  pdfScale: number;
  setPdfScale: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
  numPages: number | null;
  fileCategory?: FileCategoryTranslatedToKr;
  id?: number;
  projectStatus?: BidProjectStatus;
  isWithdrawn?: boolean;
  dataType: BidUploadDataType;
  attachmentId: number;
  handleFilePreviewClose?: () => void;
  isNewWindow: boolean;
  fileAuthority?: ForwardingAdminFileAuthority;
  isAdmin?: boolean;
  isPurchaseRequest?: boolean;
  hidesDeleteButton?: boolean;
}) {
  const [showsDeleteFileModal, setShowsDeleteFileModal] = useState(false);

  const handleDeleteFileModalClose = useCallback(() => {
    setShowsDeleteFileModal(false);
  }, []);

  const handleDeleteFileModalOpen = useCallback(() => {
    setShowsDeleteFileModal(true);
  }, []);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageNumber(page);
  };

  const handleFileDownload = () => {
    window.location.href = s3Url;
  };

  const handleImageZoomInClick = () => {
    setImageWidth(imageWidth + 10);
  };

  const handleImageZoomOutClick = () => {
    setImageWidth(imageWidth - 10);
  };

  const handleNewWindowOpenClick = () => {
    sessionStorage.setItem("s3Url", s3Url);
    sessionStorage.setItem("fileExtension", fileExtension);
    sessionStorage.setItem("fileName", fileName);
    sessionStorage.setItem("dataType", dataType);
    sessionStorage.setItem("attachmentId", attachmentId.toString());
    window.open("/file/preview");
  };

  return (
    <Grid container direction="row" alignItems="flex-end">
      <Grid item xs={3} container direction="column" spacing={1}>
        <Grid item>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ color: blue[700] }}
          >
            {fileCategory || ""}
          </Typography>
        </Grid>

        <Grid item>
          <Tooltip title={fileName}>
            <Typography variant="subtitle2" component="div">
              {omitWithEllipsis({
                src: fileName,
                maxLength: 15,
                ellipsis: `...${fileExtension}`,
              })}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>

      {!isHwp && !isPdf && !checkIsOfficeFile(fileExtension) && (
        <Grid item xs={5}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleImageZoomOutClick}
          >
            <ZoomOutOutlined />
          </Button>

          <Button
            variant="outlined"
            size="small"
            onClick={handleImageZoomInClick}
          >
            <ZoomInOutlined />
          </Button>
        </Grid>
      )}

      {isPdf && (
        <Grid item xs={5} container alignItems="center">
          <Grid item xs={6}>
            <Pagination
              size="small"
              siblingCount={-1}
              onChange={handlePaginationChange}
              page={pageNumber}
              count={numPages !== null ? numPages : 0}
            />
          </Grid>

          <Grid item xs={6} container spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  pdfScale >= 2 ? setPdfScale(pdfScale - 1.0) : null
                }
              >
                <ZoomOutOutlined />
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setPdfScale(pdfScale + 1.0)}
              >
                <ZoomInOutlined />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={4} container spacing={1}>
        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => handleNewWindowOpenClick()}
          >
            새창
          </Button>
        </Grid>

        <Grid item>
          <Button size="small" variant="contained" onClick={handleFileDownload}>
            다운로드
          </Button>
        </Grid>

        {!(isNewWindow || hidesDeleteButton) && (
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="error"
              disabled={
                isPurchaseRequest ||
                checkIfHaveAuthorityToDelete(fileAuthority, isAdmin)
              }
              onClick={handleDeleteFileModalOpen}
            >
              삭제
            </Button>
          </Grid>
        )}
      </Grid>

      {showsDeleteFileModal && (
        <DeleteFileModal
          id={id}
          projectStatus={projectStatus}
          isWithdrawn={isWithdrawn}
          dataType={dataType}
          attachmentId={attachmentId}
          showsDeleteFileModal={showsDeleteFileModal}
          handleDeleteFileModalClose={handleDeleteFileModalClose}
          handleFilePreviewClose={handleFilePreviewClose}
        />
      )}
    </Grid>
  );
}

export default FileViewerController;

import React, { useCallback } from "react";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@sellernote/shared/src/componentsToMoveToV1/button/Button";
import { APP_NAME } from "@sellernote/shared/src/constants";
import { handleLoginSubmitByEnter } from "@sellernote/shared/src/utils/common/auth";
import InputText from "@sellernote/shared/src/sds-v1/components/input/InputText";

import Styled from "./index.styles";

export default function LoginForAdmin({
  title,
  login,
  isLoadingOfLogin,
  ResponseHandlerOfLogin,
  isLoggedIn,
  RightSideElem,
  backTo,
}: {
  title?: string;
  login: ({ id, password }: { id: string; password: string }) => void;
  isLoadingOfLogin: boolean;
  ResponseHandlerOfLogin: React.ReactNode;
  isLoggedIn?: boolean;
  RightSideElem?: React.ReactNode;
  backTo?: string;
}) {
  const history = useHistory();

  if (isLoggedIn) {
    history.replace(
      backTo || (APP_NAME === "partner-admin" ? "/trello/general" : "/")
    );
  }

  const [loginFormState, setLoginFormState] = useState<{
    id?: string;
    password?: string;
  }>({
    id: "",
    password: "",
  });

  const isAllInputValid = useMemo(() => {
    return loginFormState.id && loginFormState.password;
  }, [loginFormState]);

  const canLogin = useMemo(() => {
    return isAllInputValid && !isLoadingOfLogin;
  }, [isAllInputValid, isLoadingOfLogin]);

  const handleLoginClick = useCallback(() => {
    if (!canLogin) return;

    const { id, password } = loginFormState;
    if (id && password) {
      login({ id, password });
    }
  }, [canLogin, loginFormState, login]);

  const handleEnter = useCallback(() => {
    handleLoginClick();
  }, [handleLoginClick]);

  return (
    <Styled.background>
      <Styled.container>
        <div className="left-side">
          <h2>{title || "관리자 로그인"}</h2>
          <form
            onKeyPress={(e) =>
              handleLoginSubmitByEnter({ e, onLoginSubmit: handleLoginClick })
            }
          >
            <InputText
              borderType="filled"
              valueType="string"
              label="ID"
              placeholder="입력해주세요."
              value={loginFormState.id}
              setValue={(v) => {
                setLoginFormState({
                  ...loginFormState,
                  id: v,
                });
              }}
              handleEnter={handleEnter}
              isValidated={!!loginFormState.id}
            />

            <InputText
              borderType="filled"
              valueType="password"
              label="비밀번호"
              placeholder="입력해주세요."
              value={loginFormState.password}
              setValue={(v) => {
                setLoginFormState({
                  ...loginFormState,
                  password: v,
                });
              }}
              isValidated={!!loginFormState.password}
              handleEnter={handleEnter}
            />
          </form>

          <Button
            theme="primary"
            size="normal"
            label="로그인"
            handleClick={handleLoginClick}
            disabled={!canLogin}
          />
        </div>

        {RightSideElem && <div className="right-side">{RightSideElem}</div>}
      </Styled.container>

      {ResponseHandlerOfLogin}
    </Styled.background>
  );
}

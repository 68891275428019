import { Box, Typography } from "@mui/material";

import {
  TeamBid,
  TeamSearchLog,
} from "@sellernote/shared/src/types/forwarding/adminTeam";

function CheckPointInfo({ infoData }: { infoData: TeamBid | TeamSearchLog }) {
  return (
    <Box>
      <Typography variant="body2" component="div">
        통관의뢰여부: {infoData.containCustoms ? "예" : "아니오"}
      </Typography>

      <Typography variant="body2" component="div">
        내륙운송 의뢰여부: {infoData.inlandType ? "예" : "아니오"}
      </Typography>
    </Box>
  );
}

export default CheckPointInfo;

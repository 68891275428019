import { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import DoubleArrow from "@mui/icons-material/DoubleArrow";

import { APP_BUILD_INFO, APP_NAME } from "@sellernote/shared/src/constants";
import { formatDate } from "@sellernote/shared/src/utils/common/date";

import { PARTNER_ADMIN_MENU_TREE, SHIPDA_ADMIN_MENU_TREE } from "./constants";
import MainMenu from "./MainMenu";
import Styled from "./index.styles";

function Navigation() {
  const history = useHistory();

  const [isHidden, setIsHidden] = useState(false);

  const menuList = (() => {
    if (APP_NAME === "shipda-admin") {
      return SHIPDA_ADMIN_MENU_TREE;
    }

    return PARTNER_ADMIN_MENU_TREE;
  })();

  return (
    <>
      {isHidden ? (
        <Styled.menuWhenHidden
          onClick={(e) => {
            e.stopPropagation();
            setIsHidden(false);
          }}
        >
          <div className="show">
            <DoubleArrow sx={{ color: "#9e9e9e", fontSize: "24px" }} />
          </div>
        </Styled.menuWhenHidden>
      ) : (
        <Styled.menu>
          <Styled.menuLogo
            onClick={() => {
              if (APP_NAME === "partner-admin") {
                return;
              }
              history.push("/");
            }}
          >
            <>
              <img
                src="/images/logo.png"
                alt="logo"
                style={{ width: "80px" }}
              />
              BackOffice
            </>
          </Styled.menuLogo>

          {APP_BUILD_INFO && (
            <div className="app-build-info">
              <div className="built-at">
                {`빌드: ${formatDate({
                  date: APP_BUILD_INFO.builtAt,
                  type: "YY_MM_DD_HH_mm_ss",
                })}`}
              </div>

              <div className="hidden">
                {`배포된 브랜치: ${APP_BUILD_INFO.gitBranch}`}
              </div>

              <div className="hidden">
                {`배포된 커밋 SHA: ${APP_BUILD_INFO.gitCommitSha}`}
              </div>
            </div>
          )}

          <Styled.menuTree>
            {menuList.map((menuSection, i) => {
              return (
                <Styled.menuSection key={i}>
                  <div className="label">{menuSection.label}</div>

                  <div className="menu-list">
                    {menuSection.mainMenuList.map((mainMenu, mi) => {
                      return <MainMenu mainMenu={mainMenu} key={mi} />;
                    })}
                  </div>
                </Styled.menuSection>
              );
            })}
          </Styled.menuTree>

          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsHidden(true);
            }}
            className="hide"
          >
            <DoubleArrow sx={{ color: "#9e9e9e", fontSize: "24px" }} />
          </div>
        </Styled.menu>
      )}
    </>
  );
}

export default withRouter(Navigation);

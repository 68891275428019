import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

import { CURRENCY_CODE_LIST } from "@sellernote/shared/src/constants/common/common";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import ADMIN_ORDER_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";
import { CreatePOForm } from "@sellernote/shared/src/types/forwarding/adminOrder";
import { CorpSizeType } from "@sellernote/shared/src/types/forwarding/adminTeam";
import {
  multiplyByBigNumber,
  toFixedFloat,
} from "@sellernote/shared/src/utils/common/number";
import regEx from "@sellernote/shared/src/utils/common/regEx";

import FileUpload from "../../../../FileUpload";
import Modal from "../../../../Modal";
import SelectWithReactHookForm from "../../../../SelectWithReactHookForm";
import TextFieldWithReactHookForm from "../../../../TextFieldWithReactHookForm";
import ShipmentDataForm from "./ShipmentDataForm";
import useFileUpload from "./useFileUpload";

function POCreateModal({
  opensPOCreateModal,
  setOpensPOCreateModal,
  userId,
  teamId,
  setSelectedTeamId,
  corpSizeType,
}: {
  opensPOCreateModal: boolean;
  setOpensPOCreateModal: (value: boolean) => void;
  teamId: number | undefined;
  userId: number;
  setSelectedTeamId: (value: number | undefined) => void;
  corpSizeType: CorpSizeType;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const {
    mutate: createPO,
    isLoading,
    ResponseHandler: ResponseHandlerOfCreatePO,
  } = ADMIN_ORDER_QUERY.useCreatePO();

  const {
    handleFileUpload,
    fileList,
    setFileList,
    ResponseHandler: ResponseHandlerOfFileUpload,
  } = useFileUpload();

  const methods = useForm<CreatePOForm>({
    mode: "onChange",
    defaultValues: {
      productName: "",
      poNumber: "",
      exporter: {
        companyName: "",
        personName: "",
        personPhone: "",
        personEmail: "",
      },
      purchasePrice: {
        currency: "USD",
        foreignPrice: undefined,
        exchangeRate: undefined,
      },
    },
  });

  const { control, handleSubmit } = methods;

  const handlePOCreate = (formData: CreatePOForm) => {
    if (!teamId) {
      handleSnackbarOpen("팀을 선택해주세요.", "error");
      return;
    }

    createPO(
      {
        ...formData,
        purchasePrice: {
          ...formData.purchasePrice,
          // 한화 금액은 따로 입력받지 않아서 계산(쉽다 웹과 동일)
          domesticPrice:
            toFixedFloat(
              multiplyByBigNumber(
                formData.purchasePrice.exchangeRate,
                formData.purchasePrice.foreignPrice
              ),
              2
            ) || 0,
        },

        shipmentData: formData.shipmentData
          ? {
              ...formData.shipmentData,
              polId: formData.shipmentData.polId,
              podId: formData.shipmentData.podId,
            }
          : undefined,
        userId,
        teamId,
        corpSizeType,
      },
      {
        onSuccess: (response) => {
          setSelectedTeamId(undefined);

          if (fileList.length > 0) {
            handleFileUpload({
              POId: response.data.id,
              teamId,
              onSnackbarOpen: handleSnackbarOpen,
            });
            return;
          }
          history.push(`/order/${response.data.id}`);
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return (
    <>
      <Modal
        isOpened={opensPOCreateModal}
        handleClose={() => setOpensPOCreateModal(false)}
        modalBody={
          <FormProvider {...methods}>
            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={handleSubmit(handlePOCreate)}
            >
              <Grid item xs={12}>
                발주서 등록
              </Grid>
              <Grid item xs={3}>
                <TextFieldWithReactHookForm
                  name={"productName"}
                  control={control}
                  required={true}
                  label="상품명"
                  fullWidth
                />
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={3}>
                  <TextFieldWithReactHookForm
                    name={"exporter.companyName"}
                    control={control}
                    required={true}
                    label="수출자 회사명"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldWithReactHookForm
                    name={"exporter.personName"}
                    control={control}
                    required={true}
                    label="수출자 담당자 이름"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldWithReactHookForm
                    name={"exporter.personPhone"}
                    control={control}
                    required={true}
                    label="수출자 담당자 연락처"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldWithReactHookForm
                    name={"exporter.personEmail"}
                    control={control}
                    required={true}
                    label="수출자 이메일"
                    pattern={
                      // email validation은 라이브러리(email-validator)를 사용하지만 여기서는 pattern이 직접적으로 필요해서 사용
                      regEx.email
                    }
                    errorMessage="이메일 형식에 맞게 입력해주세요."
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={3}>
                  <SelectWithReactHookForm
                    label="인보이스금액(통화)"
                    name={"purchasePrice.currency"}
                    control={control}
                    options={CURRENCY_CODE_LIST}
                    required={true}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldWithReactHookForm
                    name={"purchasePrice.foreignPrice"}
                    control={control}
                    required={true}
                    label="인보이스금액(외화)"
                    type={"number"}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldWithReactHookForm
                    name={"purchasePrice.exchangeRate"}
                    control={control}
                    required={true}
                    label="적용환율"
                    type={"number"}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextFieldWithReactHookForm
                  name={"poNumber"}
                  control={control}
                  required={true}
                  label="PO 번호"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  isMultiple={true}
                  files={fileList}
                  setFiles={setFileList}
                />
              </Grid>

              {corpSizeType === "SME" ? (
                <ShipmentDataForm portList={portList} />
              ) : (
                <Typography color="error" variant="body2">
                  SME 운송정보를 입력하기 위한 유저데이터의 문제가 있습니다.
                </Typography>
              )}

              <Grid item xs={12}>
                <Button disabled={isLoading} variant="outlined" type="submit">
                  {isLoading ? <CircularProgress size={25} /> : "등록"}
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        }
      />

      {ResponseHandlerOfCreatePO}

      {ResponseHandlerOfFileUpload}
    </>
  );
}

export default POCreateModal;

import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { Button, Grid, Typography } from "@mui/material";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import { APP_NAME } from "@sellernote/shared/src/constants";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { ADMIN_BID_USER_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_USER_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { ADMIN_PROMOTION_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_PROMOTION_QUERY";
import { ADMIN_TEAM_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_TEAM_QUERY";
import { CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { BidUploadDataType } from "@sellernote/shared/src/types/common/upload";
import { BidProjectStatus } from "@sellernote/shared/src/types/forwarding/bid";

function DeleteFileModal({
  showsDeleteFileModal,
  handleDeleteFileModalClose,
  id,
  projectStatus,
  isWithdrawn,
  dataType,
  attachmentId,
  handleFilePreviewClose,
}: {
  showsDeleteFileModal: boolean;
  handleDeleteFileModalClose: () => void;
  id?: number;
  projectStatus?: BidProjectStatus;
  isWithdrawn?: boolean;
  dataType: BidUploadDataType;
  attachmentId: number;
  handleFilePreviewClose?: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    mutate: deleteFile,
    ResponseHandler: ResponseHandlerOfUseDeleteFile,
  } = ADMIN_FILE_QUERY.useDeleteFile({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        if (dataType === "trello" && id) {
          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId: id })
          );

          //TODO: 매출&매입 복수등록 API 작업 이후 위 getTrelloAttachments로 통일 예정
          if (APP_NAME === "shipda-admin") {
            queryClient.invalidateQueries(
              TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
            );
          } else {
            queryClient.invalidateQueries(
              CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidDetail({
                bidId: id,
              })
            );
          }
        } else if (dataType === "partner") {
          queryClient.invalidateQueries(["forwarding", "partner", "list"]);
        } else if (dataType === "promotion") {
          queryClient.invalidateQueries(ADMIN_PROMOTION_QUERY_KEY_GEN.all());
        } else if (dataType === "user" && id) {
          queryClient.invalidateQueries(
            ADMIN_BID_USER_QUERY_KEY_GEN.getAdminNewBidUserDetail(id)
          );
        } else if (dataType === "team" && id) {
          queryClient.invalidateQueries(
            ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamDetail(id)
          );
        } else if (dataType === "bid" && id) {
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({ bidId: id })
          );
        }

        handleDeleteFileModalClose();
        handleFilePreviewClose && handleFilePreviewClose();
      },
      customizeMessage: () => ({
        title: "파일을 삭제했습니다.",
      }),
    },
  });

  const handleDeleteFile = useCallback(() => {
    if (isWithdrawn) {
      handleSnackbarOpen(
        "출금요청한 거래명세서는 삭제가 불가능합니다.",
        "warning"
      );
      return;
    }

    if (
      projectStatus === "inSettlement" ||
      projectStatus === "settlementComplete"
    ) {
      handleSnackbarOpen("정산 상태에서는 삭제가 불가능합니다.", "warning");
      return;
    }

    deleteFile({ attachmentId });
  }, [
    attachmentId,
    deleteFile,
    handleSnackbarOpen,
    isWithdrawn,
    projectStatus,
  ]);

  const ModalBody = useMemo(() => {
    return (
      <>
        <Typography variant="h6" component="div">
          파일을 삭제하시겠습니까?
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteFile}
            >
              예
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="success"
              onClick={handleDeleteFileModalClose}
            >
              아니오
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }, [handleDeleteFile, handleDeleteFileModalClose]);

  return (
    <>
      <Modal
        isOpened={showsDeleteFileModal}
        handleClose={handleDeleteFileModalClose}
        modalBody={ModalBody}
        sx={{ zIndex: 1400 }}
      />

      {ResponseHandlerOfUseDeleteFile}
    </>
  );
}

export default DeleteFileModal;

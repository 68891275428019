import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";

import useMuiSelect from "@sellernote/shared/src/hooks/admin/useMuiSelect";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { CorpSizeType } from "@sellernote/shared/src/types/common/team";
import { JoinedAllTeamItem } from "@sellernote/shared/src/types/forwarding/adminBidUser";

import { saveShipmentCreationInfoToSessionStorage } from "../utils";

import Modal from "../../../Modal";
import POCreateModal from "./POCreateModal";

function IndividualOrTeamSelectModal({
  opensIndividualOrTeamSelectModal,
  setOpensIndividualOrTeamSelectModal,
  joinedAllTeam,
  userId,
  userName,
  userCompany,
  teamId,
  isPOCreate,
  corpSizeType,
}: {
  opensIndividualOrTeamSelectModal: boolean;
  setOpensIndividualOrTeamSelectModal: (value: boolean) => void;
  joinedAllTeam?: JoinedAllTeamItem[];
  userId: number;
  userName: string;
  userCompany: string;
  teamId: number;
  isPOCreate: boolean;
  corpSizeType: CorpSizeType;
}) {
  const { handleSnackbarOpen: showSnackbar } = useSnackbar();

  const history = useHistory();

  const [selectedTeamId, setSelectedTeamId] = useState<number | undefined>(
    teamId
  );

  const [opensPOCreateModal, setOpensPOCreateModal] = useState(false);

  const getTeamSelectOptions = () => {
    if (!joinedAllTeam) {
      return [];
    }

    return joinedAllTeam.map((v) => {
      return { label: `${v.name} (id:${v.id})`, value: v.id };
    });
  };

  const {
    selectedValue: individualOrTeamSelectValue,
    MuiSelect: IndividualOrTeamSelect,
  } = useMuiSelect({
    options: [
      { label: "개인", value: "individual" },
      { label: "팀", value: "team" },
    ],
    title: "생성 유형",
    fullWidth: true,
    defaultValue: "individual",
  });

  const { selectedValue: teamSelectedValue, MuiSelect: TeamSelect } =
    useMuiSelect({
      options: getTeamSelectOptions(),
      title: "팀 선택",
      fullWidth: true,
    });

  const getTeamName = () => {
    if (!joinedAllTeam) {
      return userName;
    }

    return (
      joinedAllTeam.find((v) => {
        return v.id === teamSelectedValue;
      })?.name || userName
    );
  };

  const handleMoveToShipmentCreatePage = () => {
    saveShipmentCreationInfoToSessionStorage({
      // 버튼 disabled 조건으로 number를 확인
      teamId: (teamSelectedValue as number) || teamId,
      userId,
      userName:
        individualOrTeamSelectValue === "user" ? userName : getTeamName(),
      userCompany,
      corpSizeType,
    });

    history.push("/bid/create");
  };
  const handlePOCreateModalOpen = () => {
    if (isNaN(Number(teamSelectedValue))) {
      showSnackbar("유효하지 않은 팀입니다. 개발자에게 문의해주세요.", "error");
      return;
    }

    const isTeamPOCreate = individualOrTeamSelectValue === "team";

    /**
     * 발주를 생성하는 유형이 개인이면, 사용자의 teamId를 사용 (selectedTeamId의 초기값으로, setState는 생략)
     * 발주를 생성하는 유형이 팀이면, 선택한 팀의 teamId를 사용
     */
    if (isTeamPOCreate) {
      setSelectedTeamId(Number(teamSelectedValue));
    }
    setOpensPOCreateModal(true);
  };

  return (
    <Modal
      isOpened={opensIndividualOrTeamSelectModal}
      handleClose={() => setOpensIndividualOrTeamSelectModal(false)}
      modalBody={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body2">
              1. 의뢰를 생성할 "유형"을 선택하세요
            </Typography>
          </Grid>

          <Grid item>{IndividualOrTeamSelect}</Grid>

          {individualOrTeamSelectValue === "team" && (
            <Grid item>
              <Typography variant="body2">2. 팀을 선택하세요.</Typography>
            </Grid>
          )}

          {individualOrTeamSelectValue === "team" && (
            <Grid item>{TeamSelect}</Grid>
          )}

          <Grid item>
            <Button
              fullWidth
              variant="contained"
              disabled={
                // 생성 유형이 팀일 때 선택한 팀이 없는 경우
                individualOrTeamSelectValue === "team" && !teamSelectedValue
              }
              onClick={
                isPOCreate
                  ? handlePOCreateModalOpen
                  : handleMoveToShipmentCreatePage
              }
            >
              확인
            </Button>
          </Grid>

          {
            <POCreateModal
              opensPOCreateModal={opensPOCreateModal}
              setOpensPOCreateModal={setOpensPOCreateModal}
              userId={userId}
              teamId={selectedTeamId}
              setSelectedTeamId={setSelectedTeamId}
              corpSizeType={corpSizeType}
            />
          }
        </Grid>
      }
    />
  );
}

export default IndividualOrTeamSelectModal;

import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  Path,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";

import { GET_ADMIN_TEMPLATE_DETAIL_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminTemplate";
import useCheckTemplateData from "@sellernote/shared/src/hooks/admin/useCheckTemplateData";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_TEMPLATE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_TEMPLATE_QUERY";
import { Currency } from "@sellernote/shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/shared/src/types/forwarding/adminBid";
import {
  AdminBidTemplate,
  TemplateCategory,
  TemplateFeeDataType,
} from "@sellernote/shared/src/types/forwarding/adminTemplate";
import { ExchangeRate } from "@sellernote/shared/src/types/forwarding/trello";
import { useDebounce } from "@sellernote/shared/src/utils/common/hook";
import { replaceBrTagsWithNewline } from "@sellernote/shared/src/utils/forwarding/admin/adminBid";
import {
  getAdminBidTemplateSavePayload,
  getAdminWithdrawalTemplateSavePayload,
  getAllTemplateData,
  getChangeTemplateCategoryToKr,
  getTemplateData,
  getWithdrawalTemplateData,
} from "@sellernote/shared/src/utils/forwarding/admin/adminTemplate";

import SaveTemplateModal from "./SaveTemplateModal";
import TemplateDetailSelectModal from "./TemplateDetailSelectModal";
import TemplateTableModal from "./TemplateTableModal";
import useTemplateNameListForAutoComplete, {
  TemplateNameListForAutoComplete,
} from "./useTemplateNameListForAutoComplete";

function TemplateSearch({
  bidId,
  templateCategory,
  reset,
  setValue,
  watch,
  templateFeeDataType,
  templateType,
  exchangeRateList,
  changeWithdrawalCurrencyAfterTemplateUpdate,
  bidAccountPayableId,
}: {
  bidId?: number;
  templateCategory: TemplateCategory;
  reset: UseFormReset<ApplyBidFormData>;
  setValue: UseFormSetValue<ApplyBidFormData>;
  watch: UseFormWatch<ApplyBidFormData>;
  templateFeeDataType: TemplateFeeDataType;
  templateType: "bidApply" | "withdrawal";
  exchangeRateList: ExchangeRate[];
  /** 템플릿 업데이트 시 출금액의 환율 전체를 변경해주는 함수 출금액 요청에서만 사용한다. */
  changeWithdrawalCurrencyAfterTemplateUpdate?: (currency: Currency) => void;
  bidAccountPayableId?: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [searchTerm, setSearchTerm] = useState("");
  const [showsTemplateDetailSelectModal, setShowsTemplateDetailSelectModal] =
    useState(false);
  const [templateId, setTemplateId] = useState(0);
  const [templateListItem, setTemplateListItem] = useState(
    {} as AdminBidTemplate
  );
  const [templateDetailSelectModalType, setTemplateDetailSelectModalType] =
    useState<"update" | "delete">("update");
  const [showsAllTemplateTableModal, setShowsAllTemplateTableModal] =
    useState(false);
  const [showsSaveTemplateModal, setShowsSaveTemplateModal] = useState(false);
  const [isDifferentName, setIsDifferentName] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { setIsLoadTemplateData, getIsLoadTemplateData } =
    useCheckTemplateData();

  // 전체 템플릿을 불러올 때 개별항목의 검색은 제거한다.
  useEffect(() => {
    if (
      templateCategory !== "all" &&
      getIsLoadTemplateData("all") &&
      !getIsLoadTemplateData(templateCategory)
    ) {
      setSearchTerm("");
    }
  }, [getIsLoadTemplateData, templateCategory]);

  const { data: templateList } = ADMIN_TEMPLATE_QUERY.useGetAdminBidTemplates({
    templateName: debouncedSearchTerm,
    category: templateCategory,
    enabled: !debouncedSearchTerm ? false : true,
  });

  const {
    mutate: saveAdminBidTemplate,
    isLoading: saveLoading,
    ResponseHandler: ResponseHandlerOfSaveAdminBidTemplate,
  } = ADMIN_TEMPLATE_QUERY.useSaveAdminBidTemplate();

  const { templateNameListForAutoComplete } =
    useTemplateNameListForAutoComplete({
      templateList,
    });

  const getTemplateSavePayload = useCallback(() => {
    if (templateType === "withdrawal") {
      return getAdminWithdrawalTemplateSavePayload({
        withdrawalData: watch("withdrawalFormList"),
        bidId: bidId ?? 0,
        saveStatus: "update",
        category: templateCategory,
        templateName: searchTerm,
        bidAccountPayableId,
      });
    }

    return getAdminBidTemplateSavePayload({
      freightFare: watch("freightFeeData"),
      domesticFare: watch("domesticFeeData"),
      localFare: watch("localFeeData"),
      otherFare: watch("otherFeeData"),
      inlandFare: watch("inlandFeeData"),
      taxFare: watch("taxFeeData"),
      liner: watch("liner"),
      isTransit: watch("isTransit"),
      leadtime: watch("leadtime"),
      expiredAt: watch("expiredAt"),
      freightPaymentType: watch("freightPaymentType"),
      comment: watch("comment"),
      bidId: bidId ?? 0,
      saveStatus: "update",
      category: templateCategory,
      templateName: searchTerm,
    });
  }, [
    bidAccountPayableId,
    bidId,
    searchTerm,
    templateCategory,
    templateType,
    watch,
  ]);

  const handleTemplateSave = useCallback(() => {
    if (!getIsLoadTemplateData(templateCategory)) {
      return setShowsSaveTemplateModal(true);
    }

    // 저장 시에만 templateId를 넣어서 요청한다.
    const adminTemplateSavePayload = {
      ...getTemplateSavePayload(),
      templateId: templateList?.find((v) => {
        return v.name === searchTerm;
      })?.id,
    };

    return saveAdminBidTemplate(adminTemplateSavePayload, {
      onSuccess: () => {
        handleSnackbarOpen("템플릿을 저장했습니다.");
      },

      onError: () => {
        handleSnackbarOpen("템플릿 저장에 실패했습니다.", "error");
      },
    });
  }, [
    getIsLoadTemplateData,
    getTemplateSavePayload,
    handleSnackbarOpen,
    saveAdminBidTemplate,
    searchTerm,
    templateCategory,
    templateList,
  ]);

  const handleDifferentNameSave = () => {
    setIsDifferentName(true);
    setShowsSaveTemplateModal(true);
  };

  const DeleteOnlyOneTemplateData = useCallback(
    (template: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined) => {
      if (templateType === "withdrawal") {
        return setValue(
          "withdrawalFormList",
          getWithdrawalTemplateData(template, exchangeRateList)
        );
      }
      return setValue(
        templateFeeDataType as Path<ApplyBidFormData>,
        getTemplateData(template, exchangeRateList)
      );
    },
    [exchangeRateList, templateFeeDataType, setValue, templateType]
  );

  const UpdateOnlyOneTemplateData = useCallback(
    (template: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined) => {
      if (
        templateType === "withdrawal" &&
        changeWithdrawalCurrencyAfterTemplateUpdate
      ) {
        // 출금액의 기본 통화는 USD이고, 출금액에서는 하나에 통화만 사용할 수 있다.
        const withdrawalCurrency = template?.reduce(
          (acc, cur) => {
            if (cur.currency !== "KRW") {
              acc.currency = cur.currency;
            }
            return acc;
          },
          { currency: "USD" } as Record<string, Currency>
        );

        changeWithdrawalCurrencyAfterTemplateUpdate(
          withdrawalCurrency?.currency || "USD"
        );

        setValue(
          "withdrawalFormList",
          getWithdrawalTemplateData(template, exchangeRateList)
        );
        return;
      }

      if (templateCategory === "freightCost") {
        setValue("freightFeeData", getTemplateData(template, exchangeRateList));
        return;
      }
      if (templateCategory === "domesticCost") {
        setValue(
          "domesticFeeData",
          getTemplateData(template, exchangeRateList)
        );
        return;
      }
      if (templateCategory === "inlandCost") {
        setValue("inlandFeeData", getTemplateData(template, exchangeRateList));
        return;
      }
      if (templateCategory === "localCost") {
        setValue("localFeeData", getTemplateData(template, exchangeRateList));
        return;
      }
      if (templateCategory === "otherCost") {
        setValue("otherFeeData", getTemplateData(template, exchangeRateList));
        return;
      }

      setValue("taxFeeData", getTemplateData(template, exchangeRateList));
      return;
    },
    [
      changeWithdrawalCurrencyAfterTemplateUpdate,
      exchangeRateList,
      setValue,
      templateCategory,
      templateType,
    ]
  );

  const deleteTemplateData = useCallback(
    (template: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined) => {
      if (templateCategory === "all") {
        setSearchTerm("");
        setIsLoadTemplateData(templateCategory, false);
        handleSnackbarOpen("템플릿을 삭제했습니다.");
        setShowsTemplateDetailSelectModal(false);
        reset({
          freightFeeData: [],
          domesticFeeData: [],
          inlandFeeData: [],
          taxFeeData: [],
          otherFeeData: [],
          localFeeData: [],
          leadtime: 0,
          liner: "",
          freightPaymentType: "cc",
          isTransit: false,
          expiredAt: new Date(),
        });
        return;
      }

      setSearchTerm("");
      setIsLoadTemplateData(templateCategory, false);
      handleSnackbarOpen("템플릿을 삭제했습니다.");
      setShowsTemplateDetailSelectModal(false);
      DeleteOnlyOneTemplateData(template);
      return;
    },
    [
      DeleteOnlyOneTemplateData,
      handleSnackbarOpen,
      reset,
      setIsLoadTemplateData,
      templateCategory,
    ]
  );

  const updateTemplateData = useCallback(
    ({
      templateDetailData,
      templateListItem,
    }: {
      templateDetailData: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined;
      templateListItem: AdminBidTemplate;
    }) => {
      if (templateCategory === "all") {
        setValue(
          "localFeeData",
          getAllTemplateData(templateDetailData, "localCost", exchangeRateList)
        );
        setValue(
          "freightFeeData",
          getAllTemplateData(
            templateDetailData,
            "freightCost",
            exchangeRateList
          )
        );
        setValue(
          "domesticFeeData",
          getAllTemplateData(
            templateDetailData,
            "domesticCost",
            exchangeRateList
          )
        );
        setValue(
          "inlandFeeData",
          getAllTemplateData(templateDetailData, "inlandCost", exchangeRateList)
        );
        setValue(
          "otherFeeData",
          getAllTemplateData(templateDetailData, "otherCost", exchangeRateList)
        );
        setValue(
          "taxFeeData",
          getAllTemplateData(templateDetailData, "tax", exchangeRateList)
        );
        setValue("liner", templateListItem.liner || "");
        setValue("leadtime", templateListItem.leadTime || 0);

        setValue("isTransit", templateListItem.isTransit ?? false);
        setValue("expiredAt", templateListItem.expiredAt || new Date());
        setValue("comment", replaceBrTagsWithNewline(templateListItem.comment));

        return;
      }

      UpdateOnlyOneTemplateData(templateDetailData);

      return;
    },
    [templateCategory, UpdateOnlyOneTemplateData, setValue, exchangeRateList]
  );

  const handleSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleAutoCompleteChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      selectValue: TemplateNameListForAutoComplete | null
    ) => {
      // 초기화 액션을 확인
      if (!selectValue?.value && event.type === "click" && searchTerm) {
        setTemplateDetailSelectModalType("delete");
        setShowsTemplateDetailSelectModal(true);
        return;
      }

      if (selectValue?.value) {
        const templateListItem = templateList?.find((template) => {
          return template.name === selectValue?.value;
        });

        if (!templateListItem) {
          return handleSnackbarOpen("템플릿을 찾을 수 없습니다.", "error");
        }

        setTemplateDetailSelectModalType("update");
        setSearchTerm(selectValue?.value || "");
        setTemplateListItem(templateListItem);
        setTemplateId(templateListItem.id);
        setShowsTemplateDetailSelectModal(true);
        return;
      }
    },
    [handleSnackbarOpen, searchTerm, templateList]
  );

  const getSaveButtonDisabled = useCallback(() => {
    if (
      templateFeeDataType === "withdrawalFormList" ||
      templateFeeDataType === "freightFeeData" ||
      templateFeeDataType === "domesticFeeData" ||
      templateFeeDataType === "localFeeData" ||
      templateFeeDataType === "inlandFeeData" ||
      templateFeeDataType === "taxFeeData" ||
      templateFeeDataType === "otherFeeData"
    ) {
      if (
        !watch(templateFeeDataType) ||
        watch(templateFeeDataType).length < 1
      ) {
        return true;
      }
      return false;
    }

    return false;
  }, [templateFeeDataType, watch]);

  return (
    <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
      <Grid item>
        <Autocomplete
          filterOptions={(options) => options}
          size="small"
          onChange={(event, newValue: TemplateNameListForAutoComplete | null) =>
            handleAutoCompleteChange(event, newValue)
          }
          value={{
            label: searchTerm,
            value: !searchTerm ? null : searchTerm,
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={templateNameListForAutoComplete}
          sx={{ width: 250 }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={handleSearchTermChange}
              label={`${getChangeTemplateCategoryToKr(
                templateCategory
              )} 템플릿 명 검색`}
            />
          )}
        />
      </Grid>

      <Grid item>
        <Button
          variant="outlined"
          onClick={() => {
            setShowsAllTemplateTableModal(true);
          }}
        >
          찾기
        </Button>
      </Grid>

      {bidId && (
        <Grid item>
          <Button
            disabled={getSaveButtonDisabled() || saveLoading}
            variant="outlined"
            onClick={handleTemplateSave}
          >
            저장
          </Button>
        </Grid>
      )}

      {bidId && (
        <Grid item>
          <Button
            variant="outlined"
            disabled={!getIsLoadTemplateData(templateCategory)}
            onClick={handleDifferentNameSave}
          >
            다른이름으로 저장
          </Button>
        </Grid>
      )}

      {showsTemplateDetailSelectModal && (
        <TemplateDetailSelectModal
          showsTemplateDetailSelectModal={showsTemplateDetailSelectModal}
          setShowsTemplateDetailSelectModal={setShowsTemplateDetailSelectModal}
          templateId={templateId}
          templateName={searchTerm}
          updateTemplateData={updateTemplateData}
          type={templateDetailSelectModalType}
          deleteTemplateData={deleteTemplateData}
          templateListItem={templateListItem}
          templateCategory={templateCategory}
        />
      )}

      {showsAllTemplateTableModal && (
        <TemplateTableModal
          templateType={templateType}
          defaultCategory={templateCategory}
          showsTemplateTableModal={showsAllTemplateTableModal}
          setShowsTemplateTableModal={setShowsAllTemplateTableModal}
          updateTemplateData={updateTemplateData}
          setSearchTerm={setSearchTerm}
        />
      )}

      {showsSaveTemplateModal && bidId && (
        <SaveTemplateModal
          bidId={bidId}
          showsSaveTemplateModal={showsSaveTemplateModal}
          setShowsSaveTemplateModal={setShowsSaveTemplateModal}
          templateCategory={templateCategory}
          watch={watch}
          templateType={templateType}
          isDifferentName={isDifferentName}
          bidAccountPayableId={bidAccountPayableId}
        />
      )}

      {ResponseHandlerOfSaveAdminBidTemplate}
    </Grid>
  );
}
export default TemplateSearch;

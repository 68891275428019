import { useTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import { SELECT_LABEL_BY_AUTHORITY_STATUS } from "@sellernote/shared/src/constants/forwarding/team";
import { TeamAuthorityDetail } from "@sellernote/shared/src/types/common/team";

function MemberDetailModal({
  showsMemberDetailModal,
  handleMemberDetailModalClose,
  permissionDetail,
}: {
  showsMemberDetailModal: boolean;
  handleMemberDetailModalClose: () => void;
  permissionDetail: TeamAuthorityDetail | undefined;
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpened={showsMemberDetailModal}
      handleClose={handleMemberDetailModalClose}
      modalBody={
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }}>
            <TableBody>
              <TableRow>
                <TableCell>발주관리</TableCell>

                <TableCell>
                  {permissionDetail?.po
                    ? SELECT_LABEL_BY_AUTHORITY_STATUS(t)[permissionDetail.po]
                    : ""}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>운송관리</TableCell>

                <TableCell>
                  {permissionDetail?.transit
                    ? SELECT_LABEL_BY_AUTHORITY_STATUS(t)[
                        permissionDetail.transit
                      ]
                    : ""}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>센터관리</TableCell>

                <TableCell>
                  {permissionDetail?.center
                    ? SELECT_LABEL_BY_AUTHORITY_STATUS(t)[
                        permissionDetail.center
                      ]
                    : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
}

export default MemberDetailModal;

import { useCallback } from "react";
import { AlertColor } from "@mui/material";

import ADMIN_TEAM_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_TEAM_QUERY";
import { DetailType } from "@sellernote/shared/src/types/forwarding/adminBidUser";

type AdminManagerType = "forwarding" | "fullfillment" | "exportForwarding";

export default function useHandleManager({
  type,
  teamId,
  id,
  refetchDetailData,
  onSnackbarOpen,
}: {
  type: DetailType;
  teamId: number;
  id: number;
  refetchDetailData: () => void;
  onSnackbarOpen: (message: string, color?: AlertColor) => void;
}) {
  const { mutate: assignTeamManager } = ADMIN_TEAM_QUERY.useAssignTeamManager(
    type === "user" ? teamId : id
  );

  const handleManagerAssign = useCallback(
    (managerId: number, managerType: AdminManagerType) => {
      if (!managerId) return;

      const targetId = type === "team" ? id : teamId;

      const managerAssignment = {
        teamId: targetId,
        fulfillmentManagerId:
          managerType === "fullfillment" ? managerId : undefined,
        forwardingManagerId:
          managerType === "forwarding" ? managerId : undefined,
        exportForwardingManagerId:
          managerType === "exportForwarding" ? managerId : undefined,
      };

      assignTeamManager(managerAssignment, {
        onSuccess: () => {
          onSnackbarOpen("담당자가 변경되었습니다.");
          refetchDetailData();
        },
        onError: () => onSnackbarOpen("담당자 변경에 실패했습니다.", "error"),
      });
    },
    [assignTeamManager, id, onSnackbarOpen, refetchDetailData, teamId, type]
  );

  return {
    handleManagerAssign,
  };
}

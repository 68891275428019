import { Box, Button, MenuItem, Select, Typography } from "@mui/material";

import { TeamMember } from "@sellernote/shared/src/types/forwarding/adminTeam";

import Modal from "../../../Modal";

export default function UserSelectModal({
  opensModal,
  onClose,
  userList,
  onSelectedUserIdGet,
  onSelectButtonClick,
}: {
  opensModal: boolean;
  onClose: () => void;
  userList: TeamMember[] | undefined;
  onSelectedUserIdGet: (userId: number) => void;
  onSelectButtonClick: () => void;
}) {
  const teamUserList = (() => {
    if (!userList) return [];

    return userList.map((user) => ({
      id: user.userId,
      name: user.name,
    }));
  })();

  return (
    <Modal
      isOpened={opensModal}
      handleClose={onClose}
      modalBody={
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Typography variant={"h6"}>
            쿠폰을 발급할 유저를 선택해주세요.
          </Typography>

          <Select
            size="small"
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            onChange={(e) => onSelectedUserIdGet(Number(e.target.value))}
          >
            {teamUserList.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>

          <Button
            variant="contained"
            size="small"
            onClick={onSelectButtonClick}
          >
            선택
          </Button>
        </Box>
      }
    />
  );
}

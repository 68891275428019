import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import ADMIN_COUPON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COUPON_QUERY";
import { AdminCouponListItem } from "@sellernote/shared/src/types/forwarding/coupon";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";
import { convertCouponKindToKorean } from "@sellernote/shared/src/utils/forwarding/coupon";

import Modal from "../../../Modal";
import Table, { TableBodyRow } from "../../../Table";
import HEAD_CELL_LIST from "./constant";
import FreightTypeSelectModal from "./FreightTypeSelectModal";

const IssueCouponModal = ({
  opensIssueCouponModal,
  setOpensIssueCouponModal,
  resetSelectedUserId,
  teamId,
  userId,
}: {
  opensIssueCouponModal: boolean;
  setOpensIssueCouponModal: (value: boolean) => void;
  resetSelectedUserId: () => void;
  teamId: number;
  userId: number | undefined;
}) => {
  const [campaignId, setCampaignId] = useState<number | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [opensFreightTypeSelectModal, setOpensFreightTypeSelectModal] =
    useState(false);

  const { t } = useTranslation();

  const { data: couponData } = ADMIN_COUPON_QUERY.useGetAdminCouponList(
    { page: currentPage, perPage },
    true
  );

  const rows = useMemo(() => {
    if (!couponData?.list) return [];

    return couponData?.list.map((v) => {
      const row: TableBodyRow<keyof AdminCouponListItem> = {
        campaignName: (
          <Box>
            <Typography variant="body1" component={"div"}>
              {v.campaignName}
            </Typography>

            <Typography color="gray" variant="body2" component={"div"}>
              {v.comment}
            </Typography>
          </Box>
        ),
        kind: convertCouponKindToKorean({ couponKind: v.kind, t }),
        code: v.code,
        discountAmount: toThousandUnitFormat(v.discountAmount),
        startDate: `${formatDate({
          date: v.startDate,
          type: "YY_MM_DD",
        })} / ${formatDate({
          date: v.endDate,
          type: "YY_MM_DD",
        })}`,
        createdAt: formatDate({
          date: v.createdAt,
          type: "YY_MM_DD",
        }),
        handleRowClick: () => {
          setCampaignId(v.id);
          setOpensFreightTypeSelectModal(true);
        },
      };

      return row;
    });
  }, [couponData?.list, t]);

  return (
    <Modal
      isOpened={opensIssueCouponModal}
      handleClose={() => {
        resetSelectedUserId();
        setOpensIssueCouponModal(false);
      }}
      modalBody={
        <>
          <Table
            title={"쿠폰 리스트"}
            headCells={HEAD_CELL_LIST}
            rows={rows}
            pagination={{
              rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
              totalCount: couponData?.total || 0,
              perPage,
              setPerPage,
              currentPage,
              setCurrentPage,
            }}
          />

          {opensFreightTypeSelectModal && campaignId && (
            <FreightTypeSelectModal
              setOpensFreightTypeSelectModal={setOpensFreightTypeSelectModal}
              opensFreightTypeSelectModal={opensFreightTypeSelectModal}
              teamId={teamId}
              userId={userId}
              campaignId={campaignId}
            />
          )}
        </>
      }
    />
  );
};

export default IssueCouponModal;

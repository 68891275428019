import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import useHistoryState from "@sellernote/shared/src/hooks/common/useHistoryState";
import { Port } from "@sellernote/shared/src/types/common/common";
import { TeamAndUserDetail } from "@sellernote/shared/src/types/forwarding/adminBidUser";
import { TeamBid } from "@sellernote/shared/src/types/forwarding/adminTeam";
import {
  changeBidProjectStatusNameToKr,
  changeBidStatusNameToKr,
} from "@sellernote/shared/src/utils/forwarding/bid";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../LegacyTable";
import CargoInfo from "../_components/CargoInfo";
import CheckPointInfo from "../_components/CheckPointInfo";
import ShippingInfo from "../_components/ShippingInfo";

type CellId = keyof TeamBid | "cargoInfo" | "shippingInfo" | "checkpoint";

function ShipmentTable({
  detailData,
  portList,
}: {
  detailData: TeamAndUserDetail;
  portList: Port[];
}) {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useHistoryState("userBidTablePage", 0);
  const [perPage, setPerPage] = useHistoryState("userBidTablePerPage", 20);

  const handleBidDetailMove = useCallback(
    (bidId: number) => {
      return history.push(`/bid/detail/${bidId}`);
    },
    [history]
  );

  const headCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "id" as CellId,
        disablePadding: false,
        label: "운송의뢰번호",
      },

      {
        id: "userName",
        disablePadding: false,
        label: "담당자명",
        width: 100,
      },
      {
        id: "cargoInfo",
        disablePadding: false,
        label: "화물정보",
        width: 180,
      },
      {
        id: "shippingInfo",
        disablePadding: false,
        label: "운송정보",
      },
      {
        id: "checkpoint",
        disablePadding: false,
        label: "체크포인트",
        width: 180,
      },
      {
        id: "status",
        disablePadding: false,
        label: "상태",
      },
      {
        id: "projectStatus",
        disablePadding: false,
        label: "세부 상태",
      },
    ];
    return headCells;
  }, []);

  const rows = useMemo(() => {
    if (!detailData.bids) return [];

    return (
      detailData.bids
        // API 요청이 없어서 자체 페이지네이션을 한다.
        .slice(currentPage * perPage, currentPage * perPage + perPage)
        .map((shipment) => {
          const row: LegacyTableBodyRow<CellId> = {
            handleRowClick: () => handleBidDetailMove(shipment.id),
            id: shipment.id,
            userName: shipment.userName || detailData.userName,
            cargoInfo: <CargoInfo infoData={shipment} />,
            shippingInfo: (
              <ShippingInfo infoData={shipment} portList={portList} />
            ),
            checkpoint: <CheckPointInfo infoData={shipment} />,
            status: changeBidStatusNameToKr(shipment.status),
            projectStatus: changeBidProjectStatusNameToKr(
              shipment.projectStatus
            ),
          };

          return row;
        })
    );
  }, [
    currentPage,
    handleBidDetailMove,
    perPage,
    portList,
    detailData.bids,
    detailData.userName,
  ]);

  return (
    <LegacyTable
      title={"의뢰현황"}
      headCells={headCells}
      rows={rows}
      pagination={{
        rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
        totalCount: detailData?.bids?.length || 0,
        perPage,
        setPerPage,
        currentPage,
        setCurrentPage,
      }}
    />
  );
}

export default ShipmentTable;

import { useCallback, useMemo, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { IS_UNDER_PRODUCTION } from "@sellernote/shared/src/constants";
import {
  ADMIN_FORWARDING_MANAGER_OPTION_LIST,
  ADMIN_FORWARDING_MANAGERS,
} from "@sellernote/shared/src/constants/forwarding/adminAuth";

export default function useForwardingManagerSelect() {
  const [forwardingManagerId, setForwardingManagerId] = useState<number>(0);

  const handleForwardingManagerIdChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      setForwardingManagerId(event.target.value as number);
    },
    []
  );

  // prod에선 없지만 dev에서는 필요한 개발자 용 아이디 추가
  const forwardingManagerSelectOptions = useMemo(() => {
    if (IS_UNDER_PRODUCTION) {
      return ADMIN_FORWARDING_MANAGER_OPTION_LIST;
    }
    return ADMIN_FORWARDING_MANAGER_OPTION_LIST.concat([
      {
        label: ADMIN_FORWARDING_MANAGERS.bangHyunJung.name,
        value: ADMIN_FORWARDING_MANAGERS.bangHyunJung.id,
      },
    ]);
  }, []);

  const ForwardingManagerSelect = useMemo(() => {
    return (
      <FormControl sx={{ width: 200 }} size="small">
        <InputLabel>등록자</InputLabel>

        <Select
          value={forwardingManagerId}
          onChange={handleForwardingManagerIdChange}
        >
          <MenuItem value={0}>전체</MenuItem>
          {forwardingManagerSelectOptions.map((v) => {
            return (
              <MenuItem key={v.value} value={v.value}>
                {v.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }, [
    forwardingManagerId,
    forwardingManagerSelectOptions,
    handleForwardingManagerIdChange,
  ]);

  return { ForwardingManagerSelect, forwardingManagerId };
}

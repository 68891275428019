import { useMemo } from "react";

import { GET_ADMIN_TEMPLATE_LIST_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminTemplate";

export interface TemplateNameListForAutoComplete {
  label: string;
  value: string | null;
}

export default function useTemplateNameListForAutoComplete({
  templateList,
}: {
  templateList: GET_ADMIN_TEMPLATE_LIST_RES | undefined;
}) {
  const templateNameListForAutoComplete = useMemo(() => {
    if (!templateList) {
      return [];
    }
    const userCompanyNameList: TemplateNameListForAutoComplete[] =
      templateList?.map((template) => {
        return {
          label: template.name,
          value: template.name,
        };
      });

    return userCompanyNameList.concat([{ label: "", value: null }]);
  }, [templateList]);

  return { templateNameListForAutoComplete };
}

import { useState } from "react";
import { Box, Button, Chip, Divider, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/shared/src/constants";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/shared/src/jotaiStates/auth";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { getBooleanValueToKr } from "@sellernote/shared/src/utils/common/etc";
import {
  getCustomRound,
  toKg,
  toThousandUnitFormat,
} from "@sellernote/shared/src/utils/common/number";
import { checkCanEditBidItem } from "@sellernote/shared/src/utils/forwarding/admin/adminBid";
import {
  getConsolidationItemListForImport,
  getPackageLabel,
  getSupplyValueInUnit,
} from "@sellernote/shared/src/utils/forwarding/bid";

import ItemUpdateModal from "../components/ItemUpdateModal";

import SADSTable, {
  TableBodyRow,
  TableBodyTotalRow,
  TableHeadCell,
} from "../../SADSTable";
import TotalTable from "../TotalTable";
import ExporterInfo from "./ExporterInfo";
import UpdateExporterInfo from "./UpdateExporterInfo";

type TableHeadCellKey =
  | "품명"
  | "W"
  | "L"
  | "H"
  | "UNIT"
  | "PACKING"
  | "NUMBER OF BOXES"
  | "물동량"
  | "CBM"
  | "WEIGHT"
  | "위험물"
  | "냉동/냉장여부"
  | "2단적재";

const headCellList: TableHeadCell<TableHeadCellKey>[] = [
  { cellKey: "품명", label: "품명", width: "170px" },
  { cellKey: "W", label: "W", align: "right", width: "55px" },
  { cellKey: "L", label: "L", align: "right", width: "55px" },
  { cellKey: "H", label: "H", align: "right", width: "55px" },
  { cellKey: "UNIT", label: "UNIT", width: "70px" },
  { cellKey: "PACKING", label: "PACKING", width: "110px" },
  {
    cellKey: "NUMBER OF BOXES",
    label: "NUMBER OF BOXES",
    align: "right",
    width: "190px",
  },
  { cellKey: "물동량", label: "물동량", align: "right", width: "155px" },
  { cellKey: "CBM", label: "CBM", align: "right", width: "140px" },
  { cellKey: "WEIGHT", label: "WEIGHT", align: "right", width: "100px" },
  { cellKey: "위험물", label: "위험물", width: "100px" },
  { cellKey: "냉동/냉장여부", label: "냉동/냉장여부", width: "120px" },
  { cellKey: "2단적재", label: "2단적재", width: "100px" },
];

type SubTotalKey =
  | "소계"
  | "NUMBER OF BOXES"
  | "물동량"
  | "CBM"
  | "WEIGHT"
  | "empty";

type TotalKey =
  | "총계"
  | "NUMBER OF BOXES"
  | "물동량"
  | "CBM"
  | "WEIGHT"
  | "empty";

function ConsolidationCargoInfo({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  // TODO: import/export 분리하면서 정리
  const consolidationItemList = getConsolidationItemListForImport(
    bidDetail.itemGroupList || []
  );

  const [showsItemUpdateModal, setShowsItemUpdateModal] = useState(false);
  const [consolidationExporterGroupId, setConsolidationExporterGroupId] =
    useState<number | undefined>(undefined);

  const handleItemUpdateModalOpen = (consolidationExporterGroupId: number) => {
    setConsolidationExporterGroupId(consolidationExporterGroupId);
    setShowsItemUpdateModal(true);
  };

  const handleItemUpdateModalClose = () => {
    setConsolidationExporterGroupId(undefined);
    setShowsItemUpdateModal(false);
  };

  const totalRowValue = consolidationItemList.reduce(
    (acc, cur) => {
      return {
        "NUMBER OF BOXES":
          acc["NUMBER OF BOXES"] +
          cur.itemList.reduce((acc, cur) => acc + cur.quantity, 0),
        CBM: acc.CBM + cur.itemList.reduce((acc, cur) => acc + cur.cbm, 0),
        WEIGHT:
          acc.WEIGHT +
          cur.itemList.reduce((acc, cur) => {
            if (cur.weightUnit === "NONE") {
              return acc;
            }

            return acc + toKg(cur.weight * cur.quantity, cur.weightUnit);
          }, 0),
      };
    },
    { "NUMBER OF BOXES": 0, CBM: 0, WEIGHT: 0 }
  );

  const totalRow: TableBodyTotalRow<TotalKey> = {
    총계: {
      value: "총계",
      colSpan: 6,
      align: "center",
      isLabel: true,
    },
    "NUMBER OF BOXES": {
      value: totalRowValue["NUMBER OF BOXES"],
      align: "right",
    },
    물동량: {
      value: getSupplyValueInUnit(bidDetail.freightType, bidDetail.supply),
      align: "right",
    },
    CBM: {
      value: `${getCustomRound(totalRowValue.CBM, 3)}CBM`,
      align: "right",
    },
    WEIGHT: {
      value: `${totalRowValue.WEIGHT}KG`,
      align: "right",
    },
    empty: {
      value: "",
      colSpan: 3,
    },
  };

  return (
    <>
      <Grid
        display="flex"
        direction="column"
        spacing={2}
        rowGap={2}
        marginTop={1}
      >
        {consolidationItemList.map((consolidationItem, index) => {
          const bodyRowList: TableBodyRow<TableHeadCellKey>[] =
            consolidationItem.itemList.map((item) => {
              return {
                rowKey: item.id,
                품명: { value: item.name },
                W: { value: item.horizontal || "-" },
                L: { value: item.vertical || "-" },
                H: { value: item.height || "-" },
                UNIT: { value: item.volumeUnit },
                PACKING: { value: getPackageLabel(item.packingType) },
                "NUMBER OF BOXES": {
                  value: toThousandUnitFormat(item.quantity),
                },
                물동량: { value: "-" },
                CBM: { value: `${item.cbm}CBM` },
                WEIGHT: {
                  value: `${item.weight}${item.weightUnit}`,
                },
                위험물: {
                  value: getBooleanValueToKr({
                    value: item.isDangerous,
                    textType: "포함/미포함",
                  }),
                },
                "냉동/냉장여부": {
                  value: getBooleanValueToKr({
                    value: item.needRefrigeration,
                    textType: "예/아니오",
                  }),
                },
                "2단적재": {
                  value: getBooleanValueToKr({
                    value: item.canStack,
                    textType: "가능/불가",
                  }),
                },
              };
            });

          const hasSubTotalRow =
            consolidationItem.itemList[0].mode === "item" &&
            consolidationItemList.length > 1;

          const subTotalValues = consolidationItem.itemList.reduce(
            (acc, cur) => {
              if (cur.weightUnit === "NONE") {
                return acc;
              }

              return {
                "NUMBER OF BOXES": acc["NUMBER OF BOXES"] + cur.quantity,
                CBM: acc.CBM + cur.cbm,
                WEIGHT:
                  acc.WEIGHT + toKg(cur.weight * cur.quantity, cur.weightUnit),
              };
            },
            { "NUMBER OF BOXES": 0, CBM: 0, WEIGHT: 0 }
          );

          const subTotalRow: TableBodyTotalRow<SubTotalKey> = {
            소계: {
              value: "소계",
              colSpan: 6,
              align: "center",
              isLabel: true,
            },
            "NUMBER OF BOXES": {
              value: subTotalValues["NUMBER OF BOXES"],
              align: "right",
            },
            물동량: { value: "-", align: "right" },
            CBM: {
              value: `${subTotalValues.CBM}CBM`,
              align: "right",
            },
            WEIGHT: {
              value: `${subTotalValues.WEIGHT}KG`,
              align: "right",
            },
            empty: {
              value: "",
              colSpan: 3,
            },
          };

          return (
            <>
              {index > 0 && <Divider />}
              <Grid
                item
                key={consolidationItem.exporterGroup}
                sx={{ display: "flex", flexDirection: "column", rowGap: "4px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Chip
                    label={`수출자 ${index + 1} 정보`}
                    variant="outlined"
                    size="small"
                    sx={{
                      color: grey[800],
                    }}
                  />

                  {APP_NAME === "shipda-admin" && (
                    <Box sx={{ display: "flex", columnGap: "8px" }}>
                      <UpdateExporterInfo
                        bidId={bidDetail.id}
                        consolidationItem={consolidationItem}
                      />

                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        disabled={checkCanEditBidItem(
                          bidDetail.projectStatus,
                          currentAdminAuthInfo?.authority
                        )}
                        onClick={() =>
                          handleItemUpdateModalOpen(
                            consolidationItem.exporterGroup
                          )
                        }
                      >
                        화물정보 수정
                      </Button>
                    </Box>
                  )}
                </Box>

                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  sx={{
                    padding: "4px 8px",
                    backgroundColor: grey[300],
                  }}
                >
                  <ExporterInfo consolidationItem={consolidationItem} />
                </Grid>

                <Grid>
                  <SADSTable<TableHeadCellKey, SubTotalKey>
                    headCellList={headCellList}
                    bodyRowList={bodyRowList}
                    totalRow={hasSubTotalRow ? subTotalRow : undefined}
                  />
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>

      <Grid item>
        <TotalTable headCellList={headCellList} totalRow={totalRow} />
      </Grid>

      {showsItemUpdateModal && consolidationExporterGroupId && (
        <ItemUpdateModal
          bidDetail={bidDetail}
          onItemUpdateModalClose={handleItemUpdateModalClose}
          consolidationExporterGroupId={consolidationExporterGroupId}
        />
      )}
    </>
  );
}

export default ConsolidationCargoInfo;

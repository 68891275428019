import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { I18N_KEY_BY_PO_STATUS } from "@sellernote/shared/src/constants/forwarding/purchaseOrder";
import {
  DetailType,
  TeamAndUserDetail,
} from "@sellernote/shared/src/types/forwarding/adminBidUser";
import { POProjectStatus } from "@sellernote/shared/src/types/forwarding/adminOrder";
import { TeamPO } from "@sellernote/shared/src/types/forwarding/adminTeam";
import { changeBooleanValueToKr } from "@sellernote/shared/src/utils/common/etc";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../LegacyTable";

type CellId = keyof TeamPO;

function POInfo({
  detailData,
  type,
}: {
  detailData: TeamAndUserDetail;
  type: DetailType;
}) {
  const { t } = useTranslation();

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const handlePODetailMove = useCallback(
    (orderId: number) => {
      return history.push(`/order/${orderId}`);
    },
    [history]
  );

  function getPOProjectStatusKr(param: POProjectStatus) {
    switch (param) {
      case "none":
        return "파트너 컨택 전";
      case "contactPartner":
        return "운송의뢰 생성 전";
      case "completed":
        return "운송 완료";
      case "canceled":
        return "취소";
      case "failed":
        return "실패";
      default:
        return param;
    }
  }

  const HeadCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "poNumber",
        disablePadding: false,
        label: "PO번호",
      },

      {
        id: "userName",
        disablePadding: false,
        label: "담당자명",
      },
      {
        id: "productName",
        disablePadding: false,
        label: "상품명",
      },
      {
        id: "bidCount",
        disablePadding: false,
        label: "운송의뢰 수",
      },
      {
        id: "foreignPrice",
        disablePadding: false,
        label: "인보이스 금액(외화)",
      },

      {
        id: "isDepositCompleted",
        disablePadding: false,
        label: "대금결제여부",
      },
      {
        id: "status",
        disablePadding: false,
        label: "상태",
      },

      {
        id: "projectStatus",
        disablePadding: false,
        label: "세부상태",
      },
    ];
    return headCells;
  }, []);

  const rows = useMemo(() => {
    if (!detailData.pos) return [];

    return (
      detailData.pos
        // API 요청이 없어서 자체 페이지네이션을 한다.
        .slice(currentPage * perPage, currentPage * perPage + perPage)
        .map((v) => {
          const row: LegacyTableBodyRow<CellId> = {
            handleRowClick: () => handlePODetailMove(v.id),
            poNumber: v.poNumber,
            userName: type === "user" ? detailData.userName : v.userName,
            productName: v.productName,
            bidCount: v.bidCount,
            foreignPrice: `${v.currency} ${toThousandUnitFormat(
              v.foreignPrice
            )}`,
            isDepositCompleted: changeBooleanValueToKr(v.isDepositCompleted),
            status: t(I18N_KEY_BY_PO_STATUS[v.status]),
            projectStatus: getPOProjectStatusKr(v.projectStatus),
          };

          return row;
        })
    );
  }, [
    detailData.pos,
    detailData.userName,
    currentPage,
    perPage,
    type,
    t,
    handlePODetailMove,
  ]);

  return (
    <LegacyTable
      title={"발주현황"}
      headCells={HeadCells}
      rows={rows}
      pagination={{
        rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
        totalCount: detailData?.pos?.length || 0,
        perPage,
        setPerPage,
        currentPage,
        setCurrentPage,
      }}
    />
  );
}

export default POInfo;

import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";

import ConsolidationCargoInfo from "./ConsolidationCargoInfo";
import GeneralCargoInfo from "./GeneralCargoInfo";

function CargoInfo({ bidDetail }: { bidDetail: AdminBidDetail }) {
  if (!bidDetail.freightType || !bidDetail.serviceType) return null;

  return bidDetail.serviceType === "consolidation" ? (
    <ConsolidationCargoInfo bidDetail={bidDetail} />
  ) : (
    <GeneralCargoInfo bidDetail={bidDetail} />
  );
}

export default CargoInfo;

import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  toFormattedDate,
  transformUTCDateToLocalDateTime,
} from "@sellernote/shared/src/utils/common/date";

import TextField from "../TextField";

function DatePicker({
  disabled,
  value,
  setDate,
  when,
  label,
  width,
  fullWidth,
  highlightsText,
  isNotISOString,
  isDisablePast,
  showsTextFieldError,
}: {
  disabled?: boolean;
  value: string | null;
  setDate: (v: string | null) => void;
  when: "start" | "end";
  label?: string;
  width?: number;
  fullWidth?: boolean;
  highlightsText?: boolean;
  isNotISOString?: boolean;
  isDisablePast?: boolean;
  // 빈칸일 때 빨간 테두리 경고 제거
  showsTextFieldError?: boolean;
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        disablePast={isDisablePast}
        disabled={disabled}
        componentsProps={{
          actionBar: { actions: ["clear"] },
        }}
        inputFormat="yy. MM. dd."
        value={value}
        label={label || "날짜를 입력해주세요."}
        onChange={(v) => {
          if (!v) {
            setDate(v);
            return;
          }

          // Date가 아닌 잘못된 값이 들어오는지 확인
          if (isNaN(Date.parse(v))) {
            setDate(null);
            return;
          }

          if (isNotISOString) {
            // TODO: toFormattedDate > setDate 로직은 수정 보류
            setDate(toFormattedDate(v, "YYYY-MM-DD"));
            return;
          }

          setDate(
            transformUTCDateToLocalDateTime({
              utcDateTime: v,
              timeZone: "Asia/Seoul",
              when,
            })?.toISOString() || ""
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            sx={{
              width: fullWidth ? "max-width" : width || 200,
            }}
            autoComplete="off"
            size="small"
            highlightsText={highlightsText}
            error={showsTextFieldError}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;

import { ChangeEvent, RefObject, useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import TextField from "@sellernote/shared/src/admin-ui/components/TextField";
import { APP_NAME } from "@sellernote/shared/src/constants";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { ForwardingAdminAttachment } from "@sellernote/shared/src/types/common/file";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { changeFileAuthorityToKr } from "@sellernote/shared/src/utils/forwarding/admin/trello";

function FileDescription({
  attachmentDescription,
  descriptionTextFieldRef,
  attachmentId,
  id,
  attachment,
}: {
  attachmentDescription?: string;
  descriptionTextFieldRef: RefObject<HTMLInputElement>;
  attachmentId: number;
  id?: number;
  attachment: ForwardingAdminAttachment;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [fileDescription, setFileDescription] = useState(
    attachmentDescription || ""
  );

  const {
    mutate: changeFileDescription,
    ResponseHandler: ResponseHandlerOfChangeFileDescription,
  } = ADMIN_FILE_QUERY.useUpdateFileDescription(attachmentId);

  const handleFileDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFileDescription(event.target.value);
    },
    []
  );

  const handleFileDescriptionUpdate = useCallback(() => {
    if (!id) {
      return;
    }

    changeFileDescription(
      { description: fileDescription },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");

          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId: id })
          );

          //TODO: 매출&매입 복수등록 API 작업 이후 위 getTrelloAttachments로 통일 예정
          if (APP_NAME === "shipda-admin") {
            queryClient.invalidateQueries(
              TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
            );
          } else {
            queryClient.invalidateQueries(
              CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidDetail({
                bidId: id,
              })
            );
          }
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    id,
    changeFileDescription,
    fileDescription,
    handleSnackbarOpen,
    queryClient,
  ]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ color: blue[700] }}
        >
          파일 정보
        </Typography>
      </Grid>

      <Grid item container>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ color: blue[700] }}
          >
            업로드한 사람:
          </Typography>

          <Typography variant="subtitle2" component="span">
            {`${changeFileAuthorityToKr(attachment.authority)} ${
              attachment.author || ""
            }`}
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="subtitle2" component="div">
            {formatDate({
              date: attachment.createdAt,
              type: "YY_MM_DD_HH_mm_ss",
            })}
          </Typography>
        </Grid>
      </Grid>

      <Grid item>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ color: blue[700] }}
        >
          파일 설명
        </Typography>
      </Grid>

      <Grid item>
        <TextField
          inputRef={descriptionTextFieldRef}
          value={fileDescription}
          placeholder="설명을 추가하세요"
          sx={{ width: 400 }}
          size="small"
          multiline={true}
          onChange={handleFileDescriptionChange}
        />

        <Button variant="contained" onClick={handleFileDescriptionUpdate}>
          저장
        </Button>
      </Grid>

      {ResponseHandlerOfChangeFileDescription}
    </Grid>
  );
}

export default FileDescription;

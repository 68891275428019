import { TeamAndUserDetail } from "@sellernote/shared/src/types/forwarding/adminBidUser";

import AdminDetailDescription from "../../AdminDetailDescription";

function TeamInfo({ detailData }: { detailData: TeamAndUserDetail }) {
  return (
    <AdminDetailDescription
      title={"팀 정보"}
      descriptionValueList={[
        { label: "합계", value: detailData.teamCnt },
        { label: "팀 마스터", value: detailData.isMasterCnt },
        { label: "팀 마스터 외", value: detailData.isMemberCnt },
      ]}
    />
  );
}

export default TeamInfo;
